<template>
  <div style="color: #000" class="box my_warp">
    <h2 style="text-align: center; font-size: 17px;padding:15px 0">{{COMPANY_NAME_All}}直播版权保护投诉指引</h2>
    &emsp;&emsp;{{COMPANY_NAME_All}}（下称“{{COMPANY_NAME_All}}直播”）经营{{COMPANY_NAME_All}}直播网站（http://tianqi.video）。前述平台中包含众多用户上传的信息、第三方发布的信息、频道管理者发布的信息、以及其他来自互联网的信息。{{COMPANY_NAME_All}}直播为保护著作权人的合法权利，根据我国相关法律法规的规定，将通过以下途径保护{{COMPANY_NAME_All}}直播经营的网站内作品的著作权：<br>
    &emsp;&emsp;“作品”是指受《中华人民共和国著作权法》保护的文学、艺术和科学作品，包括但不限于文字作品、美术作品、表演、录音录像制品等。<br>
    &emsp;&emsp;“权利人”是指作品的著作权人或者经著作权人合法授权而享有作品的信息网络传播权的权利享有人。<br>
    通知程序<br>
    &emsp;&emsp;权利人认为{{COMPANY_NAME_All}}直播经营的网站中相关信息、搜索或链接的内容所涉及的作品侵犯了其信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可向{{COMPANY_NAME_All}}直播提交书面通知，要求删除该作品，或者断开该作品的链接。通知书需权利人亲笔签名，若为单位则需加盖单位公章。<br>
    通知书应当包含下列内容： 
    &emsp;&emsp;（一）权利人的姓名（名称）、联系方式、地址、身份证复印件（自然人）、单位登记证明复印件（单位）；<br>
    &emsp;&emsp;（二） 要求删除或者断开链接的侵权作品的准确名称和网络地址； <br>
    &emsp;&emsp;（三）认为构成侵权的初步证明材料，包括但不限于对作品享有著作权或依法享有信息网络传播权的权属证明、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书、列举证据证明侵权事实。权利人应对通知书的真实性负责。若通知书的内容不真实，权利人将承担由此造成的全部法律责任。
    {{COMPANY_NAME_All}}直播收到权利人的通知及完整的证明材料后，将立即删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接。<br>
    反通知程序<br>
    &emsp;&emsp;{{COMPANY_NAME_All}}直播将投诉通知到作品提供者，若内容提供者认为作品不侵权的可提供相关证据反通知到{{COMPANY_NAME_All}}直播。反通知书需作品提供者亲笔签名，若为单位则需加盖单位公章。<br>
    &emsp;&emsp;邮寄地址 {{ ADDRESS }} {{COMPANY_NAME_All}} 法务部<br>
    &emsp;&emsp;邮编：401121 联系邮箱：xzb@nineton.cn<br>
  </div>
</template>

<script>
export default {
  data(){
    return{
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
      COMPANY_NAME_ABB:process.env.VUE_APP_COMPANY_NAME_ABB,
      ADDRESS:process.env.VUE_APP_ADDRESS,    
    }
  }
};
</script>

<style lang="less" scoped>
.box{
  font-size: 12px;
}
</style>